import React from "react";
import "./RawHTMLStyled.scss";

export default function RawHTMLStyled({ children, appearance = "normal" }) {
  return (
    <div className={`rawHtmlStyled rawHtmlStyled--${appearance}`}>
      {children}
    </div>
  );
}
