import React, { useEffect, useRef, useState } from "react";
import throttle from "lodash.throttle";
import debounce from "lodash.debounce";
import "./ParagraphPointer.scss";

const minOpacity = 0;
const maxOpacity = 1;

export default function ParagraphPointer({ linkedTo = "" }) {
  const mEl = useRef(null);
  const [stop, setStop] = useState(false);
  const [opa, setOpa] = useState(minOpacity);
  const [top, setTop] = useState(0);

  function calcVisibility(p) {
    let o = 0;

    if (linkedTo === "#p2" && window.innerWidth < 1024) {
      const np = document.querySelector("#p1");
      if (!np) return false;
      o =
        np.getBoundingClientRect().top + np.getBoundingClientRect().height + 35;
    } else {
      o = p.getBoundingClientRect().top + 5;
    }

    return o;
  }

  function validAction(element, parent, returnValue) {
    const rVal = returnValue !== undefined ? returnValue : false;
    const opacityStart = calcVisibility(parent);
    const scrollTop =
      (window.pageYOffset || document.documentElement.scrollTop) -
      window.innerHeight / 6;

    const { top: parentTop } = parent.getBoundingClientRect();
    const { top: elementTop } = element.getBoundingClientRect();

    if (stop !== true && scrollTop >= parentTop && scrollTop <= elementTop) {
      if (scrollTop >= opacityStart) {
        setOpa(maxOpacity);
      } else {
        setOpa(maxOpacity);
      }
      if (!rVal) {
        setTop(scrollTop - parentTop);
      } else {
        return scrollTop - parentTop;
      }
    } else if (scrollTop <= parentTop) {
      setTop(0);
      setOpa(minOpacity);
    } else if (scrollTop >= elementTop) {
      setStop(true);
      setOpa(maxOpacity);

      if (!rVal) {
        setTop(elementTop - parentTop);
      } else {
        return elementTop - parentTop;
      }
    }
  }

  function setInicialTop() {
    if (linkedTo === "") return 0;

    const e = document.querySelector(linkedTo);

    if (e === undefined) return 0;

    const p = mEl.current.parentNode;

    validAction(e, p, true);
  }

  function scrollToEle() {
    if (linkedTo === "") return false;

    const e = document.querySelector(linkedTo);

    if (e === undefined) return false;

    const p = mEl.current.parentNode;

    window.addEventListener(
      "scroll",
      throttle(() => {
        validAction(e, p);
      }, 99),
      { passive: true }
    );

    window.addEventListener(
      "resize",
      debounce(() => {
        validAction(e, p);
      }, true),
      { passive: true }
    );
  }

  useEffect(() => {
    scrollToEle();
  });

  return (
    <div
      ref={mEl}
      style={{ top: `${top}px`, opacity: opa }}
      className={`paragraph-pointer paragraph-pointer--${linkedTo.replace(
        /#/gi,
        ""
      )}`}
    >
      <div className="paragraph-pointer__pointer" />
    </div>
  );
}
