import React from "react";
import "./Heading.scss";

export default function Heading({
  children,
  type = "h1",
  appearance = "h1",
  ...props
}) {
  const Tag = type;

  return (
    <Tag className={`heading heading--${appearance}`} {...props}>
      {children}
    </Tag>
  );
}
