import React, { useState, useEffect } from "react";
import "./HeaderSkew.scss";

const transparentImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgAQAAAABbAUdZAAAAAnRSTlMAAHaTzTgAAAALSURBVHgBYxjkAAAAoAABVCqexwAAAABJRU5ErkJggg==";

function HeaderTitle({ title, url }) {
  return (
    <h1
      className={`header-skew__title ${
        url ? `header-skew__title--${url}` : ""
      }`}
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
  );
}

function HeaderSubtitle({ subtitle, url }) {
  return (
    <p
      className={`header-skew__subtitle ${
        url ? `header-skew__subtitle--${url}` : ""
      }`}
    >
      {subtitle}
    </p>
  );
}

export default function HeaderSkew({
  title = "",
  background = false,
  backgroundColor = "orange",
  appearance = "normal",
  offsetImage = false,
  offsetValue = 0,
  subtitle = "",
  url = false,
  offsetImageMobile = false,
  children,
}) {
  const [backgroundSource, setBackgroundSource] = useState(null);

  const TagOffsetImage = offsetImage;

  useEffect(() => {
    if (background) {
      const img = new Image();
      img.src = background;
      img.onload = () => setBackgroundSource(background);
    }
  }, [background]);

  const regularBackground = backgroundSource
    ? { backgroundImage: `url(${backgroundSource || transparentImage})` }
    : {};

  const classNameHeader = `header-skew header-skew--${appearance} header-skew--${backgroundColor}`;

  const classNameHeaderOffset = `header-skew__content_offset ${
    offsetImageMobile ? "header-skew__content_offset--mobile" : ""
  } `;

  return (
    <div className={classNameHeader}>
      {offsetImage && (
        <div className={classNameHeaderOffset}>
          <div className="header-skew__content_offset_wrapper">
            <div
              className="header-skew__content_tag"
              style={{ position: "relative", top: `${offsetValue}px` }}
            >
              <TagOffsetImage />
            </div>
          </div>
        </div>
      )}
      <div
        className={`header-skew__content ${
          background ? "header-skew__content--hasBackground" : ""
        } ${
          backgroundSource ? "header-skew__content--active" : ""
        } header-skew__content--${url}`}
      >
        {title && <HeaderTitle title={title} url={url} />}
        {subtitle && <HeaderSubtitle subtitle={subtitle} url={url} />}
        {children}
      </div>
      <div className={`header-skew__wrapper `}>
        <div
          className="header-skew__background"
          className={`header-skew__background ${
            background && "header-skew__background--hasBackground"
          } ${backgroundSource && "header-skew__background--active"}
          ${url ? `header-skew__background--${url}` : ""} `}
          style={regularBackground}
        ></div>
      </div>
    </div>
  );
}
