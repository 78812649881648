import React from "react";
import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import ContentGrid from "components/ContentGrid";
import Heading from "components/Heading";
import PointerParent from "components/PointerParent";
import RawHTMLStyled from "components/RawHTMLStyled";

import ScrollToTop from "components/ScrollToTop";

import backgroundOverlay from "assets/images/header_skew/generic_header.jpg";

export default function esignConsentPage() {
  const title = "E-Sign Consent";
  const url = "accessibility-statement";

  const points = [
    "headingslim1",
    "headingslim2",
    "headingslim3",
    "headingslim4",
    "headingslim5",
    "headingslim6",
  ];

  return (
    <Main page={"fqa"} pagetitle={title}>
      <HeaderSkew
        title={title}
        url="faq"
        background={backgroundOverlay}
        appearance="small"
      />

      <PointerParent points={points}>
        <Block layer="1" name="FAQ" guides={1} appearance="regular-bottomless">
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[0]}>
              Consent to Conduct Business Electronically (e-sign consent)
            </Heading>
            <RawHTMLStyled>
              <>
                <p>
                  CONSENT TO CONDUCT BUSINESS ELECTRONICALLY <br /> The loan for
                  which you are applying involves various disclosures, records,
                  and documents (“Loan Documents”), including this Agreement.
                  The purpose of this Agreement is to obtain Your consent to
                  receive certain Loan Documents from Us (Loanpal, LLC) in
                  electronic form rather than in paper form. With Your consent,
                  you will also be able to sign and authorize these Loan
                  Documents electronically, rather than on paper. Before We can
                  engage in this transaction electronically, it is important
                  that You understand Your rights and responsibilities. Please
                  read the following and affirm Your consent to conduct business
                  with Us electronically.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[1]}>
              Your Consent
            </Heading>
            <RawHTMLStyled>
              <>
                <ul>
                  <li>
                    Your consent to participate in this transaction
                    electronically will apply to all Loan Documents for the
                    applicable loans for which You are applying. If You provide
                    Your consent, we will conduct this transaction
                    electronically, instead of providing You with the Loan
                    Documents in paper form. All Loan Documents we provide to
                    you in electronic form will be considered “in writing.”
                  </li>
                  <li>
                    With your consent you agree and we both intend the Federal
                    Electronic Signatures in Global and National Commerce Act to
                    apply to both this Esign consent and our ability to conduct
                    business with you electronically.
                  </li>
                  <li>
                    If a document related to Your loan is not available in
                    electronic form, a paper copy will be provided to You free
                    of charge.
                  </li>
                  <li>
                    Conducting this transaction electronically is an option. If
                    You choose not to consent, paper Loan Documents will be
                    mailed to You free of charge.
                  </li>
                </ul>
              </>
            </RawHTMLStyled>
          </ContentGrid>
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[2]}>
              Withdrawal of Consent
            </Heading>
            <RawHTMLStyled>
              <>
                <ul>
                  <li>
                    You have the right to withdraw Your consent at any time. If
                    you decline or revoke Your consent, We will provide You with
                    the Loan Documents in paper form free of charge.
                  </li>
                  <li>
                    If You originally consent to receive Your Loan Documents
                    electronically, but later decide to withdraw Your consent,
                    please notify us at:{" "}
                    <a
                      href="tel:1.844.LOANPAL"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#555", textDecoration: "underline" }}
                    >
                      1.844.LOANPAL
                    </a>{" "}
                    or at Loanpal: 8781 Sierra College Blvd., Roseville, CA
                    95661
                  </li>
                </ul>
              </>
            </RawHTMLStyled>
          </ContentGrid>
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[3]}>
              Obtaining Paper Copies
            </Heading>
            <RawHTMLStyled>
              <>
                <ul>
                  <li>
                    After Your consent is given, You may request from Us paper
                    copies of Your Loan Documents free of charge.
                    <br />
                    Please send this request to Us at:
                    <br />
                    Phone:{" "}
                    <a
                      href="tel:1.844.LOANPAL"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#555", textDecoration: "underline" }}
                    >
                      1.844.LOANPAL
                    </a>{" "}
                    or at Loanpal: 8781 Sierra College Blvd., Roseville, CA
                    95661
                  </li>
                </ul>
              </>
            </RawHTMLStyled>
          </ContentGrid>
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[4]}>
              System Requirements
            </Heading>
            <RawHTMLStyled>
              <>
                <ul>
                  <li>
                    In order to receive Your Electronic Loan Documents, You must
                    have a computer with Internet access and an Internet email
                    account and address; an Internet browser using 128-bit
                    encryption or higher, Adobe Acrobat 7.0 or higher, SSL
                    encryption and access to a printer or the ability to
                    download information in order to keep copies of Your
                    Electronic Loan Documents for Your records.
                  </li>
                  <li>
                    If the software or hardware requirements change in the
                    future, and You are unable to continue receiving Your
                    Electronic Loan Documents, paper copies of such Loan
                    Documents will be mailed to You once You notify Us that You
                    are no longer able to access Your Electronic Loan Documents
                    because of the changed requirements. We will use
                    commercially reasonable efforts to notify You before such
                    requirements change. If You choose to withdraw Your consent
                    upon notification of the change, You will be able to do so
                    without penalty.
                  </li>
                </ul>
              </>
            </RawHTMLStyled>
          </ContentGrid>
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[5]}>
              How We Can Reach You
            </Heading>
            <RawHTMLStyled>
              <>
                <ul>
                  <li>
                    You must promptly notify Us if there is a change in Your
                    email address or in other information needed to contact You
                    electronically. You can contact Us at: Phone:{" "}
                    <a
                      href="tel:1.844.LOANPAL"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#555", textDecoration: "underline" }}
                    >
                      1.844.LOANPAL
                    </a>{" "}
                    or at Loanpal: 8781 Sierra College Blvd., Roseville, CA
                    95661
                  </li>
                  <li>
                    We will not assume liability for non-receipt of notification
                    of the availability of Your Electronic Loan Documents in the
                    event Your email address on file is invalid; Your email or
                    Internet service provider filters the notification as “spam”
                    or “junk mail”; there is a malfunction in Your computer,
                    browser, internet service and/or software; or for other
                    reasons beyond Our control.
                  </li>
                </ul>
              </>
            </RawHTMLStyled>
          </ContentGrid>
        </Block>
      </PointerParent>
      <ScrollToTop />
    </Main>
  );
}
