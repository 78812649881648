import React from "react";

import ParagraphPointer from "components/ParagraphPointer";

export default function PointerParent({ points = [], children , appearance=false}) {
  return (
    <div className={`pointer-parent ${appearance ? appearance :""}` }>
      {points.map(
        (point) =>
          point && <ParagraphPointer linkedTo={`#${point}`} key={point} />
      )}
      <>{children}</>
    </div>
  );
}
